import React, { useEffect } from "react"
import Styles from "../styles/privacy_policy.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { BACK_BUTTON_STR } from "../constant/const";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";

const TermsPage = () => {

    const headerOption = {
        headerTitle: "プライバシーポリシー",
        leftIcon: BACK_BUTTON_STR,
    }

    const isLoggedIn = useIsLoggedIn()


    useEffect( () => {
        document.documentElement.scrollTop = 0
    }, [] )

    return (
        <Layout
            headerOption={ headerOption }
            footerHide={ typeof window !== "undefined" ?
                !( isLoggedIn ) :
                true }>
            <>
                <SEO title="PrivacyPolicy" />
                <div className={ Styles.container }>
                    <div>
                        <h1 style={ { fontSize: "1.8rem", margin: "16px 0" } }>プライバシーポリシー</h1>
                        <div style={ { lineHeight: 1.4 } }>
                            <p>
                                本サービスを運営する者または組織（以下「運営者」といいます。）は、運営者の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
                            </p>
                            <ul className="privacy_policy_list">
                                <li>
                                    1.	収集する利用者情報及び収集方法
                                    <p>
                                        本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき運営者が収集するものを意味するものとします。
                                        本サービスにおいて運営者が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
                                    </p>
                                    <br />
                                    <br />(1)	ユーザーからご提供いただく情報
                                    <br />本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
                                    <br />
                                    <br />・氏名、生年月日、性別、職業等プロフィールに関する情報
                                    <br />・メールアドレス、電話番号、住所等連絡先に関する情報
                                    <br />・クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報
                                    <br />・ユーザーの肖像を含む静止画情報
                                    <br />・入力フォームその他運営者が定める方法を通じてユーザーが入力または送信する情報
                                    <br />
                                    <br />(2)	ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
                                    <br />
                                    <br />ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
                                    <br />
                                    <br />・当該外部サービスでユーザーが利用するID
                                    <br />・その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
                                    <br />
                                    <br />(3)	ユーザーが本サービスを利用するにあたって、運営者が収集する情報
                                    <br />運営者は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
                                    <br />
                                    <br />・リファラ
                                    <br />・IPアドレス
                                    <br />・サーバーアクセスログに関する情報
                                    <br />・Cookie、ADID、IDFAその他の識別子
                                    <br />
                                    <br />(4)	ユーザーが本サービスを利用するにあたって、運営者がユーザーの個別同意に基づいて収集する情報
                                    <br />運営者は、ユーザーが3-1に定める方法により個別に同意した場合、運営者は以下の情報を利用中の端末から収集します。
                                    <br />
                                    <br />・位置情報
                                </li>
                                <li>
                                    <br />2.	利用目的
                                    <br />本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
                                    <br />(1)	本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
                                    <br />(2)	ユーザーのトラフィック測定及び行動測定のため
                                    <br />(3)	広告の配信、表示及び効果測定のため
                                    <br />(4)	本サービスに関するご案内、お問い合わせ等への対応のため
                                    <br />(5)	本サービスに関する運営者の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                                    <br />(6)	本サービスに関する規約等の変更などを通知するため
                                </li>
                                <li>
                                    <br />3.	通知・公表または同意取得の方法、利用中止要請の方法
                                    <br />
                                    <br />3-1	以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。
                                    <br />
                                    <br />・位置情報
                                    <br />
                                    <br />3-2	ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、運営者は速やかに、運営者の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、運営者所定の方法により本サービスを退会した場合に限り、運営者はその収集又は利用を停止します。
                                </li>
                                <li>
                                    <br />4.	外部送信、第三者提供、情報収集モジュールの有無
                                    <br />4-1	本サービスでは、以下の提携先が、ユーザーの端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。
                                    <br />(1)	提携先
                                    <br />(2)	上記提携先のプライバシーポリシーのURL
                                    <br />(3)	上記提携先のオプトアウト（無効化）URL
                                    <br />
                                    <br />4-2	本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。
                                    <br />
                                    <br />(1)	情報収集モジュールの名称
                                    <br />(2)	情報収集モジュールの提供者
                                    <br />(3)	提供される利用者情報の項目
                                    <br />(4)	提供の手段・方法
                                    <br />(5)	上記提供者における利用目的
                                    <br />(6)	上記提供者における第三者提供の有無
                                    <br />(7)	上記提供者のプライバシーポリシーのURL
                                </li>
                                <li>
                                    <br />5.	第三者提供
                                    <br />運営者は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
                                    <br />
                                    <br />(1)	運営者が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                                    <br />(2)	合併その他の事由による事業の承継に伴って個人情報が提供される場合
                                    <br />(3)	第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
                                    <br />(4)	国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                                    <br />(5)	その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
                                </li>
                                <li>
                                    <br />6.	共同利用
                                    <br />運営者は、以下のとおりユーザーの個人情報を共同利用します。
                                    <br />
                                    <br />(1)	共同して利用される個人情報の項目
                                    <br />(2)	共同して利用する者の範囲
                                    <br />(3)	共同して利用する者の利用目的
                                    <br />(4)	個人情報の管理について責任を有する者の氏名または名称
                                </li>
                                <li>
                                    <br />7.	個人情報の開示
                                    <br />運営者は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、運営者が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
                                </li>
                                <li>
                                    <br />8.	個人情報の訂正及び利用停止等
                                    <br />8-1	運営者は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
                                    <br />
                                    <br />8-2運営者は、ユーザーから、ユーザーの個人情報について消去を求められた場合、運営者が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
                                    <br />
                                    <br />8-3個人情報保護法その他の法令により、運営者が訂正等または利用停止等の義務を負わない場合は、8-1および8-2の規定は適用されません。
                                </li>
                                <li>
                                    <br />9.	お問い合わせ窓口
                                    <br />ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
                                    <br />
                                    <br />住所：〒153-8505 東京都目黒区駒場4-6-1 東京大学生産技術研究所 B棟6階Be605-608
                                    <br />個人情報取扱責任者：原田眞
                                    <br />連絡先：club.cloud.jp@gmail.com
                                </li>
                                <li>
                                    <br />10.	プライバシーポリシーの変更手続
                                    <br />運営者は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、運営者所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、運営者は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を運営者のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
                                    <br />以上
                                </li>
                            </ul>
                            2020年1月3日制定
                            <br />
                        </div>
                    </div>
                </div>
            </>
        </Layout>
    )
}

export default TermsPage
